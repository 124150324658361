.tender-invitation-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#tender-invitation-button .MuiButton-root {
  text-transform: unset !important;
}

.tender-invitation-form {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden; /* chips в форме приглашений на тендеры могут вылезать за пределы */
}

.tender-invitation-form__success-overlay.success-overlay {
  z-index: 12; /* для перекрытия chips в форме приглашений на тендеры */
}